import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Directive({
  standalone: true,
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matCellDef]', // same selector as MatCellDef
  providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDef }],
})
//eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TypeSafeMatCellDef<Type> extends MatCellDef {
  //@Input() protected matCellDefDataSource = input.required<T[] | Observable<T[]> | MatTableDataSource<T>>();
  @Input() public matCellDefDataSource!: Type[] | Observable<Type[]> | MatTableDataSource<Type>;

  public static ngTemplateContextGuard<Type>(
    dir: TypeSafeMatCellDef<Type>,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ctx: any,
  ): ctx is { $implicit: Type; index: number } {
    return true;
  }
}
